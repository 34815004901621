function sFunction() {
  document.getElementById("sDropdown").classList.toggle("show");
}

function sFunction2() {
  document.getElementById("sDropdown2").classList.toggle("show");
  document.getElementById("dropUserBtn").classList.toggle("dropbtn-focus");
}

function sFunction2Close() {
  document.getElementById("sDropdown2").classList.remove("show");
  document.getElementById("dropUserBtn").classList.remove("dropbtn-focus");
}

function sFunction3() {
  document.getElementById("sDropdown3").classList.toggle("show");
}

// Close the dropdown if the user clicks outside of it
//window.onclick = function(e) {
//  if (!e.target.matches('.dropbtn')) {
//    var sDropdown = document.getElementById("sDropdown");
//    if (sDropdown.classList.contains('show')) {
//      sDropdown.classList.remove('show');
//    }
//    var sDropdown2 = document.getElementById("sDropdown2");
//    if (sDropdown2.classList.contains('show')) {
//      sDropdown2.classList.remove('show');
//    }
//    var sDropdown3 = document.getElementById("sDropdown3");
//    if (sDropdown3.classList.contains('show')) {
//      sDropdown3.classList.remove('show');
//    }
//  }
//}


function sFunction4() {
  document.getElementById("maintenanceHistory").classList.toggle("hide");
  document.getElementById("downIcon2").classList.toggle("fa-flip-vertical");
}

function sFunction5() {
  document.getElementById("thresholdHistory").classList.toggle("hide");
  document.getElementById("downIcon1").classList.toggle("fa-flip-vertical");
}

function sFunction6() {

  var alertUsers = document.getElementById("alertUsers");
  var alertHistory = document.getElementById("alertHistory");
  var btnAlertUsers = document.getElementById("btnAlertUsers");
  var btnAlertHistory = document.getElementById("btnAlertHistory");
  if (alertHistory.classList.contains('hide')) {
  } else {
    alertHistory.classList.add('hide');
    alertUsers.classList.remove('hide');
    alertUsers.classList.add('show');
    btnAlertUsers.classList.remove('alert-heading-none');
    btnAlertUsers.classList.add('alert-heading');
    btnAlertHistory.classList.remove('alert-heading');
    btnAlertHistory.classList.add('alert-heading-none');
  }

//            if (btnAlertUsers.classList.contains('alert-heading-none')) {
//                alertUsers.classList.remove('alert-heading-none');
//                alertUsers.classList.add('alert-heading');
//            }

}

function sFunction7() {

  var alertUsers = document.getElementById("alertUsers");
  var alertHistory = document.getElementById("alertHistory");
  var btnAlertUsers = document.getElementById("btnAlertUsers");
  var btnAlertHistory = document.getElementById("btnAlertHistory");
  if (alertUsers.classList.contains('show')) {
    alertUsers.classList.remove('show');
    alertUsers.classList.add('hide');
    alertHistory.classList.remove('hide');
    btnAlertUsers.classList.remove('alert-heading');
    btnAlertUsers.classList.add('alert-heading-none');
    btnAlertHistory.classList.remove('alert-heading-none');
    btnAlertHistory.classList.add('alert-heading');
  }
}


// When the user clicks anywhere outside of the modal, close it
//window.onclick = function(event) {
//  if (event.target == modal) {
//    modal.style.display = "none";
//  }
//}

function sFunction8() {
  document.getElementById("addNew").classList.toggle("hide");
}

function sFunction81() {
  document.getElementById("entities").classList.toggle("hide");
}

function sFunction82() {
  document.getElementById("users").classList.toggle("hide");
}

function sFunctionConfirm() {
  document.getElementById("confirm").classList.toggle("hide");
}
function sFunctionConfirmByID(id) {
  document.getElementById(id).classList.toggle("hide");
}

function sFunction9() {
  document.getElementById("deleteUser").classList.toggle("hide");
}

function toggleModal(id) {
  document.getElementById(id).classList.toggle("hide");
}

function sFunction99() {
  document.getElementById("deleteCorporate").classList.toggle("hide");
}

function sFunction10() {
  document.getElementById("changePassword").classList.toggle("hide");
}

function sFunction11() {
  document.getElementById("reportSettings").classList.toggle("hide");
}

function sFunction12() {
  document.getElementById("addCorporate").classList.toggle("hide");
}

function sFunction13() {
  document.getElementById("addNew1").classList.toggle("hide");
}

function sFunction14() {
  document.getElementById("changeConfig").classList.toggle("hide");
}

// function sFunction16() {
//   document.getElementById('device'+2).classList.remove('device-in-animation');
//   setTimeout(()=>{
//     document.getElementById('device'+2).classList.add('device-in-animation');},0)
//   document.getElementById('device'+2).classList.add('devices-box-animation');
//   setTimeout(function () {
//     document.getElementById(id).classList.remove('devices-box-animation');
//   }, 500);
// }
//
// function sFunction15(time) {
//   document.getElementById('dwar'+0).classList.remove('device-off-animation');
//   setTimeout(()=>{
//     document.getElementById('dwar'+0).classList.add('device-off-animation');
//   },time);
// }
// function sFunction16(time) {
//   document.getElementById('dnor'+0).classList.remove('device-off-animation');
//   setTimeout(()=>{
//     document.getElementById('dnor'+0).classList.add('device-off-animation');
//   },time);
// }

function toggleClassById(id, className) {
  document.getElementById(id).classList.toggle(className);
}

function openNav() {
  document.getElementById("mySidenav").classList.toggle("side-menu-width");
  document.getElementById("main").classList.toggle("side-menu-margin");
  document.getElementById("main2").classList.toggle("side-menu-padding");
  document.getElementById("sideMenuBtn").classList.toggle("side-menu-btn-focus");
  if (document.getElementById("projectsBtn"))
    document.getElementById("projectsBtn").classList.toggle("fa-flip-horizontal");
  //document.getElementsByClassName("main-body").classList.toggle("side-menu-margin");
}

// function openNavSet() {
//   var sideMenu = document.getElementById("mySidenav");
//   var breadCrumb = document.getElementById("main");
//   var mainBody = document.getElementById("main2");
//   if (sideMenu && sideMenu.classList.contains('side-menu-width')) {
//     if (sideMenu.classList.contains('side-menu-padding')) {
//
//     } else {
//       mainBody.classList.add('side-menu-padding');
//     }
//   } else {
//     if (sideMenu && sideMenu.classList.contains('side-menu-padding')) {
//       mainBody.classList.remove('side-menu-padding');
//     } else {
//
//     }
//   }
// }

function changeIcon(iconUrl) {
  document.getElementById("faviconIcon").attr('href', iconUrl);
}

function changeFavicon(src) {
  var link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}
